import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Em, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				TechFix PC Repair Services
			</title>
			<meta name={"description"} content={"Ваш надійний партнер для всіх потреб у ремонті ПК"} />
			<meta property={"og:title"} content={"TechFix PC Repair Services"} />
			<meta property={"og:description"} content={"Ваш надійний партнер для всіх потреб у ремонті ПК"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text6" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link1" />
			<Override slot="text5" />
			<Override slot="text1" />
			<Override slot="link" />
		</Components.Header>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Комплексні рішення для кожної проблеми з ПК
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				У TechFix PC Repair Services ми прагнемо пропонувати широкий спектр послуг, розроблених для задоволення всіх ваших потреб у ремонті комп’ютерів. Наші кваліфіковані технічні спеціалісти готові вирішити будь-яку проблему, гарантуючи відновлення оптимальної продуктивності вашого ПК. Незалежно від того, чи ви зіткнулися з апаратними збоями чи збоями програмного забезпечення, ми допоможемо вам.
			</Text>
		</Section>
		<Section padding="0 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Ремонт обладнання
					</Text>
					<Image
						src="https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-1.jpg?v=2024-06-20T19:30:27.454Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-1.jpg?v=2024-06-20T19%3A30%3A27.454Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-1.jpg?v=2024-06-20T19%3A30%3A27.454Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-1.jpg?v=2024-06-20T19%3A30%3A27.454Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-1.jpg?v=2024-06-20T19%3A30%3A27.454Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-1.jpg?v=2024-06-20T19%3A30%3A27.454Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-1.jpg?v=2024-06-20T19%3A30%3A27.454Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-1.jpg?v=2024-06-20T19%3A30%3A27.454Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						<Em>
							Надійні рішення для будь-якого обладнання{"\n"}
							<br />
						</Em>
						<br />
						Заміна екрана: тріснутий або несправний екран? Ми замінюємо екрани для всіх моделей комп'ютерів, гарантуючи високу якість деталей і точний монтаж.{"\n"}
						<br />
						<br />
						Ремонт клавіатури: клавіші залипають або не реагують? Наші фахівці можуть відремонтувати або замінити вашу клавіатуру, відновивши її до повної функціональності.{"\n"}
						<br />
						<br />
						Ремонт материнської плати: виникли проблеми з материнською платою? Ми діагностуємо та виправляємо проблеми з материнською платою, забезпечуючи безперебійну роботу вашого ПК.{"\n"}
						<br />
						<br />
						Заміна акумулятора: Ваш акумулятор не тримає заряд? Ми надаємо послуги із заміни батареї, щоб ваш комп’ютер залишався живим і готовим до роботи.
						<br />
						<br />
						{"\n"}Заміна жорсткого диска: потрібно більше пам’яті або швидша продуктивність? Ми замінюємо жорсткі диски на більші або ефективніші моделі відповідно до ваших потреб.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Усунення несправностей програмного забезпечення
					</Text>
					<Image
						src="https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19:30:27.471Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						object-position="top"
						srcSet="https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/2-3.jpg?v=2024-06-20T19%3A30%3A27.471Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						<Em>
							Ефективне вирішення проблем із програмним забезпеченням{"\n"}
							<br />
						</Em>
						<br />
						Встановлення операційної системи: потрібно почати заново? Ми встановлюємо або оновлюємо операційні системи, забезпечуючи безперебійну роботу найновішого програмного забезпечення на вашому ПК.
						<br />
						<br />
						{"\n"}Оновлення драйверів: застарілі драйвери викликають проблеми? Ми оновлюємо всі необхідні драйвери для забезпечення апаратної та програмної сумісності.
						<br />
						<br />
						{"\n"}Встановлення програми: Проблеми з установкою або запуском програмного забезпечення? Ми займаємось установкою та усуненням несправностей різних програм.{"\n"}
						<br />
						<br />
						Системні помилки: часті збої чи помилки? Ми діагностуємо та виправляємо проблеми, пов’язані з програмним забезпеченням, щоб стабілізувати вашу систему.{"\n"}
						<br />
						<br />
						Оптимізація продуктивності: Ваш ПК працює повільно? Ми оптимізуємо вашу систему для підвищення швидкості та загальної продуктивності.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Видалення вірусів і шкідливих програм
					</Text>
					<Image
						src="https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-3.jpg?v=2024-06-20T19:30:27.431Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-3.jpg?v=2024-06-20T19%3A30%3A27.431Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-3.jpg?v=2024-06-20T19%3A30%3A27.431Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-3.jpg?v=2024-06-20T19%3A30%3A27.431Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-3.jpg?v=2024-06-20T19%3A30%3A27.431Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-3.jpg?v=2024-06-20T19%3A30%3A27.431Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-3.jpg?v=2024-06-20T19%3A30%3A27.431Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-3.jpg?v=2024-06-20T19%3A30%3A27.431Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						<Em>
							Захист вашого ПК від зловмисних загроз
							<br />
						</Em>
						<br />
						{"\n"}Комплексне сканування: ми проводимо ретельне сканування, щоб виявити та видалити віруси, зловмисне та шпигунське програмне забезпечення з вашої системи.
						<br />
						<br />
						{"\n"}Налаштування захисту: щоб запобігти зараженню в майбутньому, ми встановлюємо та налаштовуємо надійне антивірусне програмне забезпечення.
						<br />
						<br />
						{"\n"}Очищення системи: ми видаляємо небажані програми та файли, які уповільнюють роботу комп’ютера та становлять загрозу безпеці.{"\n"}
						<br />
						<br />
						Відновлення даних після зараження: якщо вірус пошкодив ваші дані, ми працюємо над відновленням ваших важливих файлів.
						<br />
						<br />
						{"\n"}Поради щодо безпеки: ми надаємо поради та найкращі методи захисту вашого ПК від майбутніх загроз.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Відновлення даних
					</Text>
					<Image
						src="https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19:30:27.444Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19%3A30%3A27.444Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19%3A30%3A27.444Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19%3A30%3A27.444Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19%3A30%3A27.444Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19%3A30%3A27.444Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19%3A30%3A27.444Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-4.jpg?v=2024-06-20T19%3A30%3A27.444Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						<Em>
							Отримання ваших цінних даних{"\n"}
							<br />
						</Em>
						<br />
						Відновлення видалених файлів: Ви випадково видалили важливі файли? Ми можемо відновити видалені файли з вашого ПК.
						<br />
						<br />
						{"\n"}Відновлення пошкоджених даних: якщо ваші файли пошкоджені, ми використовуємо вдосконалені методи їх відновлення.{"\n"}
						<br />
						<br />
						Відновлення зовнішнього диска: втратили дані на зовнішніх дисках? Ми відновлюємо файли з USB-накопичувачів, зовнішніх жорстких дисків тощо.{"\n"}
						<br />
						<br />
						Відновлення розділів: проблеми з розділами жорсткого диска? Ми можемо відновити дані з втрачених або пошкоджених розділів.
						<br />
						<br />
						{"\n"}Рішення для резервного копіювання: щоб запобігти втраті даних у майбутньому, ми пропонуємо надійні рішення для резервного копіювання, адаптовані до ваших потреб.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Оптимізація продуктивності
					</Text>
					<Image
						src="https://uploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-2.jpg?v=2024-06-20T19:30:27.433Z"
						display="block"
						margin="0px 0px 20px 0px"
						max-width="100%"
						max-height="300px"
						width="100%"
						height="100%"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-2.jpg?v=2024-06-20T19%3A30%3A27.433Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-2.jpg?v=2024-06-20T19%3A30%3A27.433Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-2.jpg?v=2024-06-20T19%3A30%3A27.433Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-2.jpg?v=2024-06-20T19%3A30%3A27.433Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-2.jpg?v=2024-06-20T19%3A30%3A27.433Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-2.jpg?v=2024-06-20T19%3A30%3A27.433Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d98e2e8e00217d2cbe/images/1-2.jpg?v=2024-06-20T19%3A30%3A27.433Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--dark">
						<Em>
							Підвищення швидкості та ефективності вашого ПК{"\n"}
							<br />
						</Em>
						<br />
						Оновлення пам’яті: потрібно більше оперативної пам’яті? Ми вдосконалюємо вашу пам’ять, щоб покращити багатозадачність і загальну швидкість.{"\n"}
						<br />
						<br />
						Встановлення SSD: бажаєте швидшого завантаження та продуктивності? Ми встановлюємо твердотільні накопичувачі (SSD) для значного збільшення швидкості.{"\n"}
						<br />
						<br />
						Очищення системи: перевантажені непотрібними файлами та програмами? Ми очищаємо вашу систему для кращої продуктивності.{"\n"}
						<br />
						<br />
						Оптимізація запуску: повільний час запуску? Ми оптимізуємо ваш процес запуску, щоб ви швидко почали працювати.{"\n"}
						<br />
						<br />
						Користувацькі конфігурації: Маєте певні потреби в продуктивності? Ми налаштовуємо параметри вашої системи для оптимальної продуктивності.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Зв’яжіться з нами, щоб дізнатися більше
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Це лише короткий огляд того, що може запропонувати служба ремонту ПК TechFix. Щоб отримати повний спектр наших послуг і обговорити ваші конкретні потреби, зв’яжіться з нами або відвідайте наш сервісний центр. Наша команда готова надати детальну інформацію та допомогти вам із будь-якими потребами щодо ремонту ПК, які можуть виникнути.
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b7ae60009abe001f027d1c"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});